// extracted by mini-css-extract-plugin
export var chatgptCasesExpertiseSection = "R_jk";
export var chatgptCasesSection = "R_jn";
export var chatgptDevExpertiseSection = "R_jp";
export var chatgptIndustriesSection = "R_jh";
export var chatgptPrimeSection = "R_jf";
export var chatgptProcessSection = "R_jl";
export var chatgptProjLogoSection = "R_jr";
export var chatgptQuotesSection = "R_js";
export var chatgptServicesSection = "R_jg";
export var chatgptTechStackSection = "R_jm";
export var chatgptTrustedSection = "R_jj";
export var chatgptWhyChooseSection = "R_jq";